import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import EffectiveWaysFeatured from 'images/featured-image/5-effective-ways-to-improve-your-order-fulfillment-process-featured.png';
import EffectiveWaysToImproveImg from 'images/blog-images/5-effective-ways-to-improve-your-order-fulfillment-process.png';
import QualityPartnersImg from 'images/blog-images/shipkoo-quality-partner.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query EffectiveWaysImg {
      file(relativePath: { eq: "5-effective-ways-to-improve-your-order-fulfillment-process-banner.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo warehouse and inventory"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Effective Ways to Improve Your Order Fulfillment Process | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-5-effective-ways" })}
         meta={
          [
            {
              name: `description`,
              content: "Improving the order fulfillment process isn’t a simple task. It is important you ship the orders to your customers hassle-free. Check this blog to learn more.",
            },
            {
              property: `og:title`,
              // content: "Effective Ways to Improve Your Order Fulfillment Process",
              content: intl.formatMessage({ id: "blog-post-meta-title-5-effective-ways" }),
            },
            {
              property: `og:description`,
              content: "Improving the order fulfillment process isn’t a simple task. It is important you ship the orders to your customers hassle-free. Check this blog to learn more.",
            },
            {
              property: `og:image`,
              content: EffectiveWaysFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="5-effective-ways-to-improve-your-order-fulfillment-process"
        title="5 Effective Ways to Improve Your Order Fulfillment Process"
        date="2020-07-15"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              The order fulfillment process describes the steps that a company should take from the moment they receive an order until it reaches the hand of the customers.
            </p>

            <p>
              If you want your e-commerce business to be successful, it is important you ship the orders to your customers hassle-free. Here are a couple of practical ways to improve your order fulfillment process:
            </p>

            <Image src={EffectiveWaysToImproveImg} className="" alt="shipkoo warehouse and inventory"/>

            <h5>
              <strong>
                1. Keep accurate inventory
              </strong>
            </h5>

            <p>
              Have you ever ordered a product online to find out that it’s out of stock, which will result in shipping delays? It is frustrating, right? 
            </p>

            <p>
              Therefore, it is highly essential to keep track of exactly how much inventory you have.
              Customer satisfaction will undoubtedly suffer if your company consistently suffers from shipping delays. That’s why <Link to="/blog-post/inventory-management-using-excel">inventory management</Link> is so crucial to streamlining the order fulfillment process.
            </p>

            <p>
              Having accurate inventory means you can provide a consistent and dependable service to your customers.
            </p>

            <h5>
              <strong>
                2. Strong supplier communication
              </strong>
            </h5>

            <p>
              Only a few eCommerce entrepreneurs manufacture or keep the products they sell themselves. This means that they have at least one or probably multiple suppliers, whom they have to rely on to fulfill orders. Having a third-party logistics provider can bring about various issues, such as the inability to oversee daily ground-level processes.
            </p>

            <p>
              The most effective way of resolving this issue is to integrate with your supplier. Communicating and sharing data through software between the retailer and supplier can reduce future problems such as miscommunication.
            </p>

            <p>
              Knowing and providing a clear status of orders in real-time will help your delivery network keep track of progress, identify issues, and delight customers.
            </p>

            <h5>
              <strong>
                3. Slotting optimization
              </strong>
            </h5>

            <p>
              Slotting is all about managing the picking processes in your distribution centers. An effective way of handling and organizing products is by placing popular items near shipping areas to speed up the fulfillment process.
            </p>

            <p>
              Slotting Optimization can be difficult to implement, but proves to be one of the most useful.
            </p>

            <p>
              It is a good practice of slotting items during seasonal sales. Nothing worse than getting customer attention and not being able to keep up with the demand. Compare sales performance based on date to determine which season brings maximum revenue and which products make it to the top. A seasonal sales analysis can help you better your order fulfillment process.
            </p>

            <p>
              In summary, storing popular items in locations that don’t require excess time and effort to access can speed up the picking process.
            </p>

            <h5>
              <strong>
                4. Supply chain visibility
              </strong>
            </h5>

            <p>
              Supply chain visibility is an effective strategy that can help streamline order processing and improve supply chain management visibility.
            </p>

            <p>
              It includes tracking production, product orders, and shipments to gain insights throughout your supply chain operations. In simpler terms, it’s the ability to collect data throughout your entire supply chain.
            </p>

            <p>
              When implemented successfully, this can help your business find vital information about the cause of your order fulfillment issues. Through this tactic, your order fulfillment process will run smoothly, saves time and money, and further improves your service quality.
            </p>

            <h5>
              <strong>
                 5. Work with quality partners
              </strong>
            </h5>

            <Image src={QualityPartnersImg} alt="shipkoo shipping solution for ecommerce"/>

            <p>
              People hate to wait for their orders, and they want to know if their items will arrive on time. Doing business with a quality partner is one of the keys to achieving a better order fulfilling process. Many companies offer cheap shipping offers, but not all companies provide a quality shipping service.
            </p>

            <p>
              There are many <Link to="/">shipping companies</Link> in the market right now, some offer expensive services, and some provide cheap ones, but keep in mind that the quality of your products is what matters the most in this business. Whether the price offer might be small or big could be the difference between a purchase and a lost customer for your eCommerce store.
            </p>

            <p>
              Study and research who should become your partner for fulfillment and shipping and you will surely succeed!
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;